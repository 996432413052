import { createDCHook, collapseDataFromCall } from '../client';
import { getAddressPublic, getAddressPublicV2 } from '../services/AddressPublicService';

export const useAddressPublicGetAddressPublic = createDCHook(
  'getAddressPublic',
  collapseDataFromCall(getAddressPublic),
  { injectables: ['label', 'businessUnit'], flattenData: true },
);

export const useAddressPublicGetAddressPublicV2 = createDCHook(
  'getAddressPublicV2',
  collapseDataFromCall(getAddressPublicV2),
  { injectables: ['label', 'businessUnit'], flattenData: true },
);
