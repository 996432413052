import { DC_Repositories_Base_Enumerations_BusinessUnit } from '@monorepo-types/dc';
import { DC_Repositories_Base_Enumerations_Label } from '@monorepo-types/dc';
import { ResponseModels_Customers_Address_AddressModel } from '@monorepo-types/dc';

import { request } from '../client';

type GetAddressPrivate = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  postalCode: string;
  houseNumber: string;
  customerId: string;
  houseNumberSuffix?: string;
  addServiceLocationId?: boolean;
};
/**
 * GetAddressPrivate
 * Return a complete address, or an address with possible house number suffixes
 * @returns ResponseModels_Customers_Address_AddressModel Success
 */
export function getAddressPrivate({
  businessUnit,
  label,
  postalCode,
  houseNumber,
  customerId,
  houseNumberSuffix,
  addServiceLocationId,
}: GetAddressPrivate): Promise<ResponseModels_Customers_Address_AddressModel> {
  return request({
    method: 'GET',
    path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/address`,
    query: { postalCode, houseNumber, houseNumberSuffix, addServiceLocationId },
    errors: { 400: 'Bad Request' },
  });
}

type GetAddressPrivateV2 = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  postalCode: string;
  houseNumber: string;
  customerId: string;
  houseNumberSuffix?: string;
  addServiceLocationId?: boolean;
};
/**
 * GetAddressPrivateV2
 * Return a complete address, or an address with possible house number suffixes
 * @returns ResponseModels_Customers_Address_AddressModel Success
 */
export function getAddressPrivateV2({
  businessUnit,
  label,
  postalCode,
  houseNumber,
  customerId,
  houseNumberSuffix,
  addServiceLocationId,
}: GetAddressPrivateV2): Promise<ResponseModels_Customers_Address_AddressModel> {
  return request({
    method: 'GET',
    path: `/dxpweb/v2/${businessUnit}/${label}/customers/${customerId}/address`,
    query: { postalCode, houseNumber, houseNumberSuffix, addServiceLocationId },
    errors: { 400: 'Bad Request' },
  });
}
